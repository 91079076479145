$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 800px,
  lg: 1000px,
  xl: 1920px,
);

@media (min-width: 1400px) {
  .container {
    max-width: 70% !important;
  }
}

.modal-dialog {
  max-width: none !important;
}

.modal-content {
  border-radius: 0.75rem !important;
  border: none !important;
  position: relative !important;
  background-color: initial !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
